//** CORE
import "popper.js/dist/umd/popper.min";
import "bootstrap/dist/js/bootstrap.min";

import "simplebar/dist/simplebar.min";

import { handler } from "dom-factory";

import "material-design-kit";
handler.autoInit();

//** PLUGIN SCRIPTS (NODE_MODULES)
import "jquery-mask-plugin/dist/jquery.mask.min";
import "select2/dist/js/select2.min";

import "moment-range/dist/moment-range";

import "chart.js/dist/Chart.min";
import "flatpickr/dist/flatpickr.min";

import "jqvmap";
// import "jqvmap/dist/maps/jquery.vmap.world";

// import "./vector-maps/mall-map";
// import "./vector-maps/gdp-data";

import "dropzone/dist/dropzone";

import "list.js/dist/list.min";
import "toastr/toastr";

import "dragula/dist/dragula.min";

// import "jquery-ui-dist/jquery-ui.min";
import "fullcalendar/dist/fullcalendar.min";

import "quill/dist/quill.min";

import "daterangepicker/daterangepicker";

import "snazzy-info-window/dist/snazzy-info-window.min";

import "ion-rangeslider/js/ion.rangeSlider.min";

import "easy-countdown/dest/jquery.countdown.min";

//** APP SETTINGS
import "./settings";

//** PLUGIN WRAPPERS & INITS
import "./plugins/chartjs-rounded-bar";
import "./plugins/charts";
import "./plugins/daterangepicker";
import "./plugins/dragula";
import "./plugins/dropzone";
import "./plugins/flatpickr";
import "./plugins/fullcalendar";
import "./plugins/list";
import "./plugins/quill";
import "./plugins/select2";
import "./plugins/toastr";
// import "./plugins/vector-maps";
import "./plugins/ion-rangeslider";
import "./plugins/countdown";

// window["moment-range"].extendMoment(moment);
