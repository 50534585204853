// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "jquery";

import "./controllers";
import "./core-scripts";

// load app/javascript/images folder
require.context("../images", true);

// FontAwesome
import "@fortawesome/fontawesome-free/css/all";
// Main application JS code
import "../stylesheets/application";

ActiveStorage.start();

// Connect button(s) to drawer(s)
var sidebarToggle = document.querySelectorAll('[data-toggle="sidebar"]');
sidebarToggle = Array.prototype.slice.call(sidebarToggle);

sidebarToggle.forEach(function (toggle) {
  toggle.addEventListener("click", function (e) {
    var selector =
      e.currentTarget.getAttribute("data-target") ||
      e.currentTarget.getAttribute("href") ||
      "#default-drawer";
    var drawer = document.querySelector(selector);
    if (drawer) {
      drawer.mdkDrawer.toggle();
    }
  });
});

let drawers = document.querySelectorAll(".mdk-drawer");
drawers = Array.prototype.slice.call(drawers);
drawers.forEach((drawer) => {
  drawer.addEventListener("mdk-drawer-change", (e) => {
    if (!e.target.mdkDrawer) {
      return;
    }
    document
      .querySelector("body")
      .classList[e.target.mdkDrawer.opened ? "add" : "remove"](
        "has-drawer-opened"
      );
    let button = document.querySelector('[data-target="#' + e.target.id + '"]');
    if (button) {
      button.classList[e.target.mdkDrawer.opened ? "add" : "remove"]("active");
    }
  });
});

// SIDEBAR COLLAPSE MENUS
$(".sidebar .collapse").on("show.bs.collapse", function (e) {
  e.stopPropagation();
  var parent =
    $(this).parents(".sidebar-submenu").get(0) ||
    $(this).parents(".sidebar-menu").get(0);
  $(parent).find(".open").find(".collapse").collapse("hide");
  $(this).closest("li").addClass("open");
});

$(".sidebar .collapse").on("hidden.bs.collapse", function (e) {
  e.stopPropagation();
  $(this).closest("li").removeClass("open");
});

$(".sidebar .collapse").on(
  "show.bs.collapse shown.bs.collapse hide.bs.collapse hidden.bs.collapse",
  function (e) {
    const el = new SimpleBar($(this).closest(".sidebar").get(0));
    el.recalculate();
  }
);

$('.sidebar [data-toggle="tab"]').on(
  "show.bs.tab shown.bs.tab hide.bs.tab hidden.bs.tab",
  function (e) {
    const el = new SimpleBar($(this).closest(".sidebar").get(0));
    el.recalculate();
  }
);

$(".search-form input").on("focus", function () {
  $(".search-form").addClass("highlight");
});
$(".search-form input").on("focusout", function () {
  $(".search-form").removeClass("highlight");
});

$(document).ready(function () {
  $('[data-toggle="tooltip"]').tooltip();

  $(".image-checkbox").each(function () {
    if ($(this).find('input[type="radio"]').first().attr("checked")) {
      $(this).addClass("image-checkbox-checked");
    } else {
      $(this).removeClass("image-checkbox-checked");
    }
  });

  // sync the state to the input
  $(".image-checkbox").on("click", function (e) {
    $(".image-checkbox").each(function () {
      $(this).removeClass("image-checkbox-checked");
    });

    $(".image-checkbox > input[type='radio']").each(function () {
      $(this).prop("checked", false);
    })

    $(this).toggleClass("image-checkbox-checked");

    var $checkbox = $(this).find('input[type="radio"]');
    $checkbox.prop("checked", true);

    e.preventDefault();
  });
});
